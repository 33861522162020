import { useState } from "react";
import { Avatar, Box, Menu, MenuItem, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ConfirmLogout from "./ConfirmLogout";

const LogoutContainer = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const firstName = localStorage.getItem("first-name");
  const lastName = localStorage.getItem("last-name");
  const [userName] = useState(
    `${firstName ? firstName : ""} ${lastName ? lastName : ""}`
  );
  const [confirmLogout, setConfirmLogout] = useState<boolean>(false);

  const handleLogoutClick = () => {
    setConfirmLogout(true);
    setAnchorEl(null);
  };

  const closeConfirmLogout = () => {
    setConfirmLogout(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Typography variant="body1" color="text.secondary" fontWeight="regular">
          {userName}
        </Typography>
        <Avatar
          sx={{
            width: 40,
            height: 40,
            bgcolor: "lightgray",
            color: "#000",
            fontSize: 16,
          }}
        />
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={() => navigate("/profile/change-password")}>
          Change Password
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>
      {confirmLogout && (
        <ConfirmLogout
          showModal={confirmLogout}
          closeModal={closeConfirmLogout}
        />
      )}
    </>
  );
};

export default LogoutContainer;
