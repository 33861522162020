import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  highlightScript,
  pollAggregateHelper,
  pollAggregatePercentageHelper,
  previewLesson,
  previewLessonProps,
} from "../../../utils/education";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import {
  addBookmark,
  getBookmarks,
  removeBookmark,
} from "../../../utils/webview";
import webviewHttp from "../../../utils/webviewHttp";
import Webview from "./Webview";
import CMSThemeWrapper from "../Preview/CMSThemeWrapper";
import { getLang } from "../../../utils/lang";
import { getTranslation } from "../../../utils/webviewLang";
import {
  logCustomGaEvent,
  setLessonDetails,
} from "../../../utils/webviewAnalytics";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const WebviewWrapper: React.FC = () => {
  const { unitId } = useParams();
  const pageId = useQuery().get("pageId");
  const lessonId = useQuery().get("lessonId");
  const studyId = useQuery().get("studyId");

  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState("");
  const [position, setPosition] = useState(1);
  const [pages, setPages] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sections, setSections] = useState<any[]>([]);
  const [bookmarks, setBookmarks] = useState<any[]>([]);
  const [answers, setAnswers] = useState<any>({});
  const [polls, setPolls] = useState<any>({});
  // const [spacedAnswer, setSpacedAnswer] = useState<any>({});

  const [isDarkMode] = useState(localStorage.getItem("theme") === "dark");
  const [lang] = useState(getLang());

  const currentPageObj = pages[currentPage - 1];

  useEffect(() => {
    if (currentPageObj?.id && unitId && lessonId) {
      const { pollPresent, questionPresent } = previewLessonProps(
        currentPageObj?.sections
      );
      logCustomGaEvent("em_page_enter", {
        page_id: currentPageObj?.id,
        page_title: currentPageObj?.title,
        poll: pollPresent,
        question: questionPresent,
        // spaced_learning: spacedPresent,
      });
    }
    return () => {
      if (currentPageObj?.id && unitId && lessonId) {
        logCustomGaEvent("em_page_exit", {
          page_id: currentPageObj?.id,
          page_title: currentPageObj?.title,
        });
      }
    };
  }, [currentPageObj, unitId, lessonId]);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        if (unitId && lessonId) {
          setLoading(true);
          const res: AxiosResponse = await webviewHttp.get(
            `/participant/study/${studyId}/lms/lessons/${lessonId}/units/${unitId}`
          );
          const lesson = res.data.data;
          setLessonDetails({
            lessonId: lessonId,
            lessonTitle:
              lesson?.educationLesson?.externalName ||
              lesson?.educationLesson?.name ||
              "",
            unitId: unitId,
            unitTitle: lesson.title,
          });
          let newPages = [...lesson.pages];
          newPages.sort((a: any, b: any) => {
            return a.position - b.position;
          });
          newPages = newPages.map((item: any) => {
            if (item.sections && item.sections.length > 0) {
              const newSections = [...item.sections];
              newSections.sort(function (a: any, b: any) {
                return a.position - b.position;
              });
              return {
                ...item,
                sections: newSections,
              };
            } else {
              return item;
            }
          });
          setTitle(lesson.title);
          setPosition(lesson.position);
          setPages(newPages);
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error, isDarkMode);
      }
    };
    fetchDetails();
  }, [
    studyId,
    unitId,
    lessonId,
    setTitle,
    setPages,
    setLoading,
    setPosition,
    isDarkMode,
  ]);

  useEffect(() => {
    if (pageId) {
      const index = pages.findIndex((page: any) => {
        return page.id === pageId;
      });
      if (index !== -1) {
        setCurrentPage(index + 1);
      } else {
        setCurrentPage(1);
      }
    } else {
      setCurrentPage(1);
    }
  }, [pageId, setCurrentPage, pages]);

  const fetchQuestionResponses = useCallback(
    async (pageId: string) => {
      const userId = localStorage.getItem("userId") || "";
      const res: AxiosResponse = await webviewHttp.get(
        `/participant/study/${studyId}/lms/lessons/${lessonId}/question-response?participantId=${userId}&educationLessonPageId=${pageId}`
      );
      const ans: any = {};
      res.data.data.forEach((response: any) => {
        ans[response.questionId] = {
          textValue: response.textValue || undefined,
          questionChoiceIds: response.questionChoiceIds || undefined,
          submitted: true,
        };
      });
      return ans;
    },
    [studyId, lessonId]
  );

  // const fetchSpacedQuestionResponses = useCallback(
  //   async (pageId: string) => {
  //     const res: AxiosResponse = await webviewHttp.get(
  //       `/study/${studyId}/lms/lessons/${lessonId}/sl-response?educationLessonPageId=${pageId}`
  //     );
  //     const ans: any = {};
  //     res.data.data.forEach((response: any) => {
  //       ans[response.questionId] = {
  //         questionChoiceIds: response.questionChoiceIds || [],
  //         submitted: response.questionChoiceIds ? true : false,
  //         explanation: response.status
  //           ? response.status === "pass"
  //             ? "correct"
  //             : "wrong"
  //           : "",
  //       };
  //     });
  //     return ans;
  //   },
  //   [lessonId]
  // );

  const fetchPollResponses = useCallback(
    async (pageId: string) => {
      const userId = localStorage.getItem("userId") || "";
      const res: AxiosResponse = await webviewHttp.get(
        `/participant/study/${studyId}/lms/lessons/${lessonId}/poll-response?participantId=${userId}&educationLessonPageId=${pageId}`
      );
      const ans: any = {};
      const aggregate: any = pollAggregateHelper(res.data.data?.groupResponses);
      res.data.data?.polls.forEach((response: any) => {
        const sectionAggregate = pollAggregatePercentageHelper(
          aggregate[response.id]
        );
        ans[response.id] = {
          value: response?.choices[0]?.id,
          submitted: true,
          aggregate: sectionAggregate,
        };
      });
      return ans;
    },
    [studyId, lessonId]
  );

  useEffect(() => {
    const findLesson = async () => {
      try {
        setLoading(true);
        const sections = pages[currentPage - 1]?.sections;
        if (sections) {
          const {
            previewSections,
            questionPresent,
            pollPresent,
            // spacedPresent,
          } = previewLesson(sections, {
            id: pages[currentPage - 1]?.id,
            title: pages[currentPage - 1]?.title,
          });
          if (questionPresent) {
            const pageId = pages[currentPage - 1]?.id;
            try {
              const answer = await fetchQuestionResponses(pageId);
              setAnswers(answer);
            } catch (err) {
              setAnswers({});
              errorToastMessage(err as Error, isDarkMode);
            }
          } else {
            setAnswers({});
          }
          if (pollPresent) {
            const pageId = pages[currentPage - 1]?.id;
            try {
              const poll = await fetchPollResponses(pageId);
              setPolls(poll);
            } catch (err) {
              setPolls({});
              errorToastMessage(err as Error, isDarkMode);
            }
          } else {
            setPolls({});
          }
          // if (spacedPresent) {
          //   const pageId = pages[currentPage - 1]?.id;
          //   try {
          //     const spaced = await fetchSpacedQuestionResponses(pageId);
          //     setSpacedAnswer(spaced);
          //   } catch (err) {
          //     setSpacedAnswer({});
          //     errorToastMessage(err as Error, isDarkMode);
          //   }
          // }
          // else {
          //   setSpacedAnswer({});
          // }
          setSections(previewSections);
        }
        if (typeof window !== "undefined") {
          window.scrollTo(0, 0);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error, isDarkMode);
      }
    };
    findLesson();
  }, [
    setSections,
    currentPage,
    pages,
    fetchQuestionResponses,
    fetchPollResponses,
    // fetchSpacedQuestionResponses,
    setLoading,
    setAnswers,
    isDarkMode,
  ]);

  const handlePagination = (offset: number, doNotlogEvent?: boolean) => {
    const newPage = currentPage + offset;
    if (newPage > 0 && newPage <= pages.length) {
      if (offset === -1) {
        logCustomGaEvent("em_page_btn_back", {
          page_id: currentPageObj?.id,
          page_title: currentPageObj?.title,
        });
      } else if (offset === 1 && !doNotlogEvent) {
        logCustomGaEvent("em_page_btn_next", {
          page_id: currentPageObj?.id,
          page_title: currentPageObj?.title,
        });
      }
      setCurrentPage(newPage);
    } else if (newPage === pages.length + 1 || newPage === pages.length + 2) {
      finishHandler();
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = highlightScript();
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const getBookmarksHandler = useCallback(
    async (studyId: any, unitId: any, lessonId: any) => {
      try {
        setLoading(true);
        const res = await getBookmarks(studyId, unitId, lessonId);
        setBookmarks(
          res.data.data.map((book: any) => book.educationLessonPageId)
        );
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error, isDarkMode);
      }
    },
    [setBookmarks, setLoading, isDarkMode]
  );

  useEffect(() => {
    getBookmarksHandler(studyId, unitId, lessonId);
  }, [getBookmarksHandler, studyId, unitId, lessonId]);

  const finishHandler = async () => {
    try {
      setLoading(true);
      const res: AxiosResponse = await webviewHttp.get(
        `/participant/study/${studyId}/lms/lessons/${lessonId}/finish-eligible?educationLessonUnitId=${unitId}`
      );
      if (!res.data.data) {
        toastMessage("warning", getTranslation("answer_all", lang), isDarkMode);
        setLoading(false);
        return;
      }
      if (currentPageObj?.id) {
        logCustomGaEvent("em_page_exit", {
          page_id: currentPageObj?.id,
          page_title: currentPageObj?.title,
        });
      }
      setLoading(false);
      if (
        localStorage.getItem("mobileOs") === "ios" &&
        //@ts-ignore
        window.webkit &&
        //@ts-ignore
        window.webkit.messageHandlers &&
        //@ts-ignore
        window.webkit.messageHandlers.callbackHandler
      ) {
        //@ts-ignore
        window.webkit.messageHandlers.callbackHandler.postMessage({
          command: "finish-unit",
        });
        //@ts-ignore
      } else if (
        localStorage.getItem("mobileOs") === "RN" &&
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage
      ) {
        //@ts-ignore
        window?.ReactNativeWebView?.postMessage?.(
          JSON.stringify({
            command: "finish-unit",
          })
        );
        //@ts-ignore
      } else if (window.glowWebview) {
        //@ts-ignore
        window.glowWebview.finishHandler();
      }
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error, isDarkMode);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("mobileOs") === "ios" &&
      //@ts-ignore
      window.webkit &&
      //@ts-ignore
      window.webkit.messageHandlers &&
      //@ts-ignore
      window.webkit.messageHandlers.callbackHandler
    ) {
      //@ts-ignore
      window.webkit.messageHandlers.callbackHandler.postMessage({
        command: "current-page",
        currentPage: currentPage,
        totalPages: pages?.length,
      });
      //@ts-ignore
    } else if (
      localStorage.getItem("mobileOs") === "RN" &&
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage
    ) {
      //@ts-ignore
      window?.ReactNativeWebView?.postMessage?.(
        JSON.stringify({
          command: "current-page",
          currentPage: currentPage,
          totalPages: pages?.length,
        })
      );
    }
  }, [currentPage, pages]);

  // const moveToPage = (newPage: number) => {
  //   if (newPage > 0 && newPage <= pages.length) {
  //     setCurrentPage(newPage);
  //   }
  // };

  const toggleBookmark = async (pageId: string, pageTitle: string) => {
    try {
      if (lessonId) {
        setLoading(true);
        if (bookmarks.includes(pageId)) {
          logCustomGaEvent("em_page_bookmark_remove", {
            page_id: pageId,
            page_title: pageTitle,
          });
          await removeBookmark(
            {
              pageId: pageId,
            },
            studyId,
            lessonId
          );
        } else {
          logCustomGaEvent("em_page_bookmark_add", {
            page_id: pageId,
            page_title: pageTitle,
          });
          await addBookmark(
            {
              pageId: pageId,
            },
            studyId,
            lessonId
          );
        }
        getBookmarksHandler(studyId, unitId, lessonId);
      }
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error, isDarkMode);
    }
  };

  const modifyQuestionResponse = (
    questionId: string,
    key: string,
    value: string
  ) => {
    setAnswers((ans: any) => {
      return { ...ans, [questionId]: { [key]: value, submitted: false } };
    });
  };

  // const modifySpacedQuestionResponse = (
  //   questionId: string,
  //   key: string,
  //   value: string
  // ) => {
  //   setSpacedAnswer((ans: any) => {
  //     return { ...ans, [questionId]: { [key]: value, submitted: false } };
  //   });
  // };

  const submitQuestion = async (
    questions: any[],
    sectionId: string,
    sectionTitle: string
  ) => {
    try {
      setLoading(true);
      const response: any[] = [];
      const submittedQues: any[] = [];
      questions.forEach((ques) => {
        if (answers[ques.id]) {
          const answer = answers[ques.id];
          const answerLabels = ques.choices
            ?.filter((ch: any) => answer?.questionChoiceIds?.includes(ch?.id))
            ?.map((ch: any) => ch?.label);
          submittedQues.push(ques.id);
          response.push({
            questionId: ques.id,
            questionTitle: ques.title,
            response: {
              questionChoiceIds: answer.questionChoiceIds,
              questionChoiceLabels: answerLabels,
              textValue:
                answer.textValue || typeof answer.textValue === "number"
                  ? answer.textValue.toString()
                  : answer.textValue,
            },
            questionType: ques.type,
            educationLessonSectionId: sectionId,
          });
        }
      });

      if (response?.length > 0 && questions?.length === response?.length) {
        const unanswered = response?.some((item: any) => {
          if (
            item.questionType === "multi_select" ||
            item.questionType === "single_select"
          ) {
            return item?.response?.questionChoiceIds?.length < 1;
          } else return !item?.response?.textValue;
        });

        if (unanswered) {
          toastMessage(
            "warning",
            getTranslation("no_response", lang),
            isDarkMode
          );
        } else {
          // const analyticsResponse = response?.map((item: any) => {
          //   const body: any = {
          //     question_id: item?.questionId,
          //     question_title: item?.questionTitle,
          //   };

          //   if (["single_select", "multi_select"].includes(item.questionType)) {
          //     body["answer_id"] = item?.response?.questionChoiceIds.join(",");
          //     body["answer_title"] =
          //       item?.response?.questionChoiceLabels.join(",");
          //   } else {
          //     body["answer_title"] = item?.response?.textValue;
          //   }

          //   return body;
          // });

          const answerValue: any = [];

          response?.forEach((item: any) => {
            if (["single_select", "multi_select"].includes(item.questionType)) {
              answerValue.push(...item?.response?.questionChoiceIds);
            } else {
              answerValue.push(item?.response?.textValue);
            }
          });

          logCustomGaEvent("em_page_qn_btn_submit", {
            page_id: currentPageObj?.id,
            page_title: currentPageObj?.title,
            section_id: sectionId,
            section_title: sectionTitle,
            question_id: response?.map((res) => res?.questionId).join(","),
            answer_value: answerValue?.map((res: any) => res).join(","),
            // response: JSON.stringify(analyticsResponse),

            // question_id: response.map((res) => res.questionId).join(","),
            // question_title: response.map((res) => res.questionTitle).join(","),
            // answer_id: response
            //   .filter((res) =>
            //     ["single_select", "multi_select"].includes(res?.questionType)
            //   )
            //   .map((res) => res.response.questionChoiceIds)
            //   .join(","),
            // answer_title: response
            //   .filter((res) =>
            //     ["single_select", "multi_select"].includes(res?.questionType)
            //   )
            //   .map((res) => res.response.questionChoiceLabels)
            //   .join(","),
          });

          await webviewHttp.post(
            `/participant/study/${studyId}/lms/lessons/${lessonId}/submit-question-response`,
            { response }
          );
          setAnswers((prev: any) => {
            const newAns = { ...prev };
            submittedQues.forEach((ques) => {
              if (newAns[ques]) {
                newAns[ques] = {
                  ...newAns[ques],
                  submitted: true,
                };
              }
            });
            return newAns;
          });
          toastMessage(
            "success",
            getTranslation("response_saved", lang),
            isDarkMode
          );
        }
      } else {
        toastMessage(
          "warning",
          getTranslation("no_response", lang),
          isDarkMode
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error, isDarkMode);
    }
  };

  // const submitSpacedQuestion = async (
  //   questionId: string,
  //   questionTitle: string,
  //   choices: any,
  //   sectionId: string,
  //   sectionTitle: string
  // ) => {
  //   try {
  //     setLoading(true);
  //     const ans = spacedAnswer[questionId];
  //     const answerLabels = choices
  //       ?.filter((ch: any) => ans?.questionChoiceIds?.includes(ch?.id))
  //       ?.map((ch: any) => ch?.label);
  //     let response: any = null;
  //     if (ans) {
  //       response = {
  //         questionId: questionId,
  //         response: {
  //           questionChoiceIds: ans.questionChoiceIds,
  //           questionChoiceLabels: answerLabels,
  //         },
  //         educationLessonSectionId: sectionId,
  //       };
  //     }
  //     logCustomGaEvent("em_page_sl_btn_submit", {
  //       page_id: currentPageObj?.id,
  //       page_title: currentPageObj?.title,
  //       section_id: sectionId,
  //       section_title: sectionTitle,
  //       response_present: ans ? true : false,
  //       question_id: questionId,
  //       // question_title: questionTitle,
  //       answer_id: response?.response?.questionChoiceIds?.join(","),
  //       // answer_title: response?.response?.questionChoiceLabels?.join(","),
  //     });
  //     if (response && response?.response?.questionChoiceIds?.length > 0) {
  //       await webviewHttp.post(
  //         `/study/${studyId}/lms/lessons/${lessonId}/submit-sl-question-response`,
  //         response
  //       );
  //       const answer = await fetchSpacedQuestionResponses(currentPageObj?.id);
  //       setSpacedAnswer(answer);
  //       toastMessage(
  //         "success",
  //         getTranslation("response_saved", lang),
  //         isDarkMode
  //       );
  //     } else {
  //       toastMessage("warning", getTranslation("no_answer", lang), isDarkMode);
  //     }
  //     setLoading(false);
  //   } catch (err) {
  //     setLoading(false);
  //     errorToastMessage(err as Error, isDarkMode);
  //   }
  // };

  const modifyPollResponse = (sectionId: string, value: string) => {
    setPolls((poll: any) => {
      return { ...poll, [sectionId]: { value: value, submitted: false } };
    });
  };

  const submitPoll = async (
    sectionId: string,
    sectionTitle: string,
    choices: any
  ) => {
    try {
      setLoading(true);
      let response: any = {};
      if (polls[sectionId]) {
        const answer = polls[sectionId];
        response = {
          sectionId: sectionId,
          value: answer.value,
        };
        // const answerTitle = choices?.find(
        //   (ch: any) => ch.id === response?.value
        // );

        logCustomGaEvent("em_page_poll_btn_submit", {
          page_id: currentPageObj?.id,
          page_title: currentPageObj?.title,
          poll_id: sectionId,
          // poll_title: sectionTitle,
          answer_id: response?.value,
          // answer_title: answerTitle?.label || "",
        });

        const res = await webviewHttp.post(
          `/participant/study/${studyId}/lms/lessons/${lessonId}/submit-poll-response`,
          { ...response }
        );
        const groupAggregate: any = pollAggregateHelper(res.data.data);
        const aggregate = pollAggregatePercentageHelper(
          groupAggregate[sectionId]
        );
        setPolls((prev: any) => {
          const newPoll = { ...prev };
          if (newPoll[sectionId]) {
            newPoll[sectionId] = {
              ...newPoll[sectionId],
              submitted: true,
              aggregate: aggregate,
            };
          }
          return newPoll;
        });
        toastMessage("success", getTranslation("poll_saved", lang), isDarkMode);
      } else {
        toastMessage(
          "warning",
          getTranslation("no_poll_response", lang),
          isDarkMode
        );
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorToastMessage(err as Error, isDarkMode);
    }
  };

  return (
    <CMSThemeWrapper isDarkMode={isDarkMode}>
      {loading && (
        <Backdrop
          open={true}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {title && pages?.length ? (
        <Webview
          title={title}
          position={position}
          sections={sections}
          currentPage={currentPage}
          handlePagination={handlePagination}
          total={pages.length}
          // moveToPage={moveToPage}
          finishHandler={finishHandler}
          bookmarks={bookmarks}
          toggleBookmark={toggleBookmark}
          isQuestionMandatory={pages[currentPage - 1]?.areQuestionsMandatory}
          pageId={pages[currentPage - 1]?.id}
          pageTitle={pages[currentPage - 1]?.title}
          answers={answers}
          modifyQuestionResponse={modifyQuestionResponse}
          submitQuestion={submitQuestion}
          modifyPollResponse={modifyPollResponse}
          submitPoll={submitPoll}
          polls={polls}
          isDarkMode={isDarkMode}
          // spacedAnswer={spacedAnswer}
          // modifySpaceResponse={modifySpacedQuestionResponse}
          // submitSpaceResponse={submitSpacedQuestion}
          lang={lang}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            bgcolor: isDarkMode ? "#252525" : "#fafafa",
          }}
        >
          {title && (
            <Typography variant="h2" color={isDarkMode ? "#fdfdff" : "#000"}>
              {getTranslation("no_page", lang)}
            </Typography>
          )}
        </Box>
      )}
    </CMSThemeWrapper>
  );
};

export default WebviewWrapper;
