import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";

interface Choice {
  id: string;
  label: string;
  position: number;
  value: string;
  score: string;
}

export interface Basic {
  title: string;
  description: string;
  variableName: string;
  sequence: number;
  level: "beginner" | "intermediate" | "advanced";
}

export interface FormConversationState {
  id: string;
  basicInfo: Basic;
  type: "text" | "yes_no" | "multiple_choice";
  choices: Choice[];
  errors: {
    basicInfo: any;
    questionConfig: any;
  };
}

const initialState: FormConversationState = {
  id: "",
  basicInfo: {
    title: "",
    description: "",
    variableName: "",
    sequence: 1,
    level: "beginner",
  },
  type: "multiple_choice",
  choices: [
    { id: uuid(), label: "", value: "", position: 1, score: "+ve" },
    { id: uuid(), label: "", value: "", position: 2, score: "+ve" },
  ],
  errors: {
    basicInfo: {},
    questionConfig: { choices: [] },
  },
};

const conversationSlice = createSlice({
  name: "conversation",
  initialState,
  reducers: {
    updateFormConversation: (
      state,
      action: PayloadAction<Partial<FormConversationState>>
    ) => {
      return { ...state, ...action.payload };
    },
    updateBasicInfo: (
      state: any,
      action: PayloadAction<{ field: keyof Basic; value: any }>
    ) => {
      state.basicInfo[action.payload.field] = action.payload.value;
    },
    setQuestionType: (
      state,
      action: PayloadAction<"text" | "yes_no" | "multiple_choice">
    ) => {
      state.type = action.payload;
      state.errors = { basicInfo: {}, questionConfig: { choices: [] } };
      if (action.payload === "yes_no") {
        state.choices = [
          { id: uuid(), label: "Yes", value: "yes", position: 1, score: "+ve" },
          { id: uuid(), label: "No", value: "no", position: 2, score: "+ve" },
        ];
      } else if (action.payload === "multiple_choice") {
        state.choices = [
          { id: uuid(), label: "", value: "", position: 1, score: "+ve" },
          { id: uuid(), label: "", value: "", position: 2, score: "+ve" },
        ];
      } else {
        state.choices = [];
      }
    },
    addChoice: (state) => {
      state.choices.push({
        id: uuid(),
        label: "",
        value: "",
        position: state.choices.length + 1,
        score: "+ve",
      });
    },
    updateChoice: (
      state,
      action: PayloadAction<{ id: string; field: keyof Choice; value: any }>
    ) => {
      const { id, field, value } = action.payload;
      const choice: any = state.choices.find((c) => c.id === id);
      if (choice) choice[field] = value;
    },
    removeChoice: (state, action: PayloadAction<string>) => {
      state.choices = state.choices.filter(
        (choice) => choice.id !== action.payload
      );
    },
    setValidationErrors: (
      state,
      action: PayloadAction<{
        basicInfo: Record<string, string>;
        questionConfig: {
          choices: Array<{ value?: string; label?: string; score?: string }>;
        };
      }>
    ) => {
      state.errors = action.payload;
    },
    resetValidationErrors: (state) => {
      state.errors = { basicInfo: {}, questionConfig: { choices: [] } };
    },
    resetFormConversation: () => initialState,
  },
});

export const {
  updateFormConversation,
  updateBasicInfo,
  setQuestionType,
  addChoice,
  removeChoice,
  updateChoice,
  resetFormConversation,
  setValidationErrors,
  resetValidationErrors,
} = conversationSlice.actions;

export default conversationSlice.reducer;
