import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface ParticipantTabState {
  categoryType: string;
  menuType: string;

  toggleLoader: boolean;
}

const initialState: ParticipantTabState = {
  categoryType: "",
  menuType: "",

  toggleLoader: false,
};

const getIntialState = (): ParticipantTabState => {
  const categoryType = sessionStorage?.getItem("category-type") ?? "";
  const menuType = sessionStorage?.getItem("menu-type") ?? "";
  return {
    ...initialState,
    categoryType,
    menuType,
  };
};

export const participantTabSlice = createSlice({
  name: "participantTab",
  initialState: getIntialState(),
  reducers: {
    setTabCategoryType: (state, action: PayloadAction<string>) => {
      state.categoryType = action.payload;
    },
    setTabMenuType: (state, action: PayloadAction<string>) => {
      state.menuType = action.payload;
    },
    setToggleLoader: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },

    reset: () => initialState,
  },
});

export const { setTabCategoryType, setTabMenuType, setToggleLoader, reset } =
  participantTabSlice.actions;

export default participantTabSlice.reducer;
