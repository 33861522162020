import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import participantsSlice from "./reducers/participantsSlice";
import userSlice from "./reducers/userSlice";
import administratorsSlice from "./reducers/administratorsSlice";
import questionSlice from "./reducers/questionSlice";
import responseSlice from "./reducers/responseSlice";
import appSlice from "./reducers/appSlice";
import studySlice from "./reducers/studySlice";
import organisationSlice from "./reducers/organisationSlice";
import featuresSlice from "./reducers/featuresSlice";
import questionTranslationSlice from "./reducers/questionTranslationSlice";
import analysisSlice from "./reducers/analysisSlice";
import foodSlice from "./reducers/foodSlice";
import cmsBuilderSlice from "./reducers/cmsBuilderSlice";
import cmsSlice from "./reducers/cmsSlice";
import exerciseCollectionSlice from "./reducers/exerciseCollectionSlice";
import healthSlice from "./reducers/healthSlice";
import participantTabSlice from "./reducers/participantTabSlice";
import conversationSlice from "./reducers/conversationSlice";
import surveySlice from "./reducers/surveySlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    participants: participantsSlice,
    administrators: administratorsSlice,
    question: questionSlice,
    questionTranslation: questionTranslationSlice,
    response: responseSlice,
    study: studySlice,
    app: appSlice,
    organisation: organisationSlice,
    features: featuresSlice,
    analysis: analysisSlice,
    food: foodSlice,
    cms: cmsSlice,
    cmsBuilder: cmsBuilderSlice,
    exerciseCollection: exerciseCollectionSlice,
    health: healthSlice,
    participantTab: participantTabSlice,
    conversation: conversationSlice,
    survey: surveySlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
