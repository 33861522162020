import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MenuLabels {
  visit: string;
  survey: string;
  repeating_data: string;
  survey_package: string;
}
export interface Features {
  [key: string]: boolean;
}
export interface Languages {
  languageCode: string;
  languageLabel: string;
}

export interface StudyState {
  name: string;
  status: string;
  primaryColor: string;
  secondaryColor: string;
  textColor: string;
  secondaryTextColor: string;
  backgroundColor: string;
  menuLabels: MenuLabels;
  features: Features;
  languages: Languages[];
  studyPermissions: any[];
  sitePermissions: { [key: string]: any };
  sitesList: any[];
  toggleLoader: boolean;
  logoUrl?: string;
  signedLogoUrl?: string;
}

const initialState: StudyState = {
  name: "",
  status: "",
  primaryColor: "#327091",
  secondaryColor: "#E3F1F4",
  textColor: "#111928",
  secondaryTextColor: "#6B7280",
  backgroundColor: "#FFF6EB",
  menuLabels: {
    visit: "Visit",
    survey: "Survey",
    repeating_data: "Repeating Data",
    survey_package: "Survey Package",
  },
  features: {},
  languages: [],
  studyPermissions: [],
  sitesList: [],
  sitePermissions: {},
  toggleLoader: false,
  logoUrl: "",
  signedLogoUrl: "",
};

export const studySlice = createSlice({
  name: "study",
  initialState: initialState,
  reducers: {
    setStudyDetails: (
      state,
      action: PayloadAction<{
        status: string;
        primaryColor: string;
        secondaryColor: string;
        textColor: string;
        secondaryTextColor: string;
        backgroundColor: string;
        name: string;
        logoUrl?: string;
        signedLogoUrl?: string;
      }>
    ) => {
      state.status = action.payload.status;
      state.primaryColor = action.payload.primaryColor;
      state.secondaryColor = action.payload.secondaryColor;
      state.textColor = action.payload.textColor;
      state.secondaryTextColor = action.payload.secondaryTextColor;
      state.backgroundColor = action.payload.backgroundColor;
      state.name = action.payload.name;
      state.logoUrl = action.payload.logoUrl;
      state.signedLogoUrl = action.payload.signedLogoUrl;
    },
    setStudyLabels: (state, action: PayloadAction<MenuLabels>) => {
      state.menuLabels = action.payload;
    },
    setFeaturesList: (state, action: PayloadAction<Features>) => {
      state.features = action.payload;
    },
    setLanguagesList: (state, action: PayloadAction<Languages[]>) => {
      state.languages = action.payload;
    },
    setRbacState: (
      state,
      action: PayloadAction<{
        studyPermissions: string[];
        sitePermissions: { [key: string]: any };
        sitesList: any[];
      }>
    ) => {
      state.studyPermissions = action.payload.studyPermissions;
      state.sitePermissions = action.payload.sitePermissions;
      state.sitesList = action.payload.sitesList;
    },
    setStateToggle: (state) => {
      state.toggleLoader = !state.toggleLoader;
    },
    reset: () => initialState,
  },
});

export const {
  setStudyDetails,
  setStudyLabels,
  setFeaturesList,
  setLanguagesList,
  setRbacState,
  setStateToggle,
  reset,
} = studySlice.actions;

export default studySlice.reducer;
