import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createNewOption, surveyChoiceField } from "../../utils/survey";

export interface SurveyState {
  formName: string;
  errors: any[];
  fetchError: string;
  loading: boolean;
  editable: boolean;
  formSubmitting: boolean;
  questions: any[];
  formId: string;
  studyId: string;
}

export const initialQuestionState: SurveyState = {
  formName: "",
  errors: [],
  fetchError: "",
  loading: true,
  editable: true,
  formSubmitting: false,
  questions: [],
  formId: "", //FormId
  studyId: "",
};

export const surveySlice = createSlice({
  name: "survey",
  initialState: initialQuestionState,
  reducers: {
    reset: () => initialQuestionState,
    setSurveyError: (state, action: PayloadAction<any[]>) => {
      state.errors = action.payload;
    },
    setFetchError: (state, action: PayloadAction<string>) => {
      state.fetchError = action.payload;
    },
    setSurveyLoader: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSurveySubmitting: (state, action: PayloadAction<boolean>) => {
      state.formSubmitting = action.payload;
    },
    resetSurveyState: (state) => {
      state.formName = "";
      state.errors = [];
      state.fetchError = "";
      state.loading = true;
      state.editable = true;
      state.formSubmitting = false;
      state.questions = [];
      state.formId = "";
      state.studyId = "";
    },
    setSurveyDetails: (state, action: PayloadAction<SurveyState>) => {
      state.formName = action.payload.formName;
      state.errors = action.payload.errors;
      state.fetchError = action.payload.fetchError;
      state.loading = action.payload.loading;
      state.editable = action.payload.editable;
      state.formSubmitting = action.payload.formSubmitting;
      state.questions = action.payload.questions;
      state.formId = action.payload.formId;
      state.studyId = action.payload.studyId;
    },
    addSurveyQuestion: (state, action: PayloadAction<any>) => {
      state.questions.push(action.payload);
    },
    insertSurveyQuestion: (
      state,
      action: PayloadAction<{
        index: number;
        question: any;
      }>
    ) => {
      state.questions.splice(action.payload.index, 0, action.payload.question);
      state.questions.forEach((q: any, ind: number) => {
        q.position = ind + 1;
      });
    },
    removeSurveyQuestion: (state, action: PayloadAction<number>) => {
      state.questions.splice(action.payload, 1);
      state.questions.forEach((q: any, ind: number) => {
        q.position = ind + 1;
      });
    },
    moveSurveyQuestion: (
      state,
      action: PayloadAction<{
        index: number;
        offset: number;
      }>
    ) => {
      const { index, offset } = action.payload;
      const len = state.questions.length;
      if (index + offset >= len || index + offset < 0) return;
      const swapItem = state.questions[index];
      const swapToItem = state.questions[index + offset];
      state.questions[index] = swapToItem;
      state.questions[index + offset] = swapItem;
      state.questions.forEach((q: any, ind: number) => {
        q.position = ind + 1;
      });
    },
    changeQuestionType: (
      state,
      action: PayloadAction<{
        questionIndex: number;
        type: string;
      }>
    ) => {
      const { type, questionIndex } = action.payload;
      let question = state.questions[questionIndex];
      if (type === "text") {
        delete question.choices;
      } else if (type === "yes_no") {
        question.choices = [
          surveyChoiceField(1, "Yes"),
          surveyChoiceField(2, "No"),
        ];
      } else {
        if (question.type === "text") {
          question.choices = [surveyChoiceField(1), surveyChoiceField(2)];
        }
      }
      question.type = type;
    },
    handleQuestionModification: (
      state,
      action: PayloadAction<{
        section: "label" | "description" | "isRequired";
        value: any;
        questionIndex: number;
      }>
    ) => {
      const { section, value, questionIndex } = action.payload;
      let question = state.questions[questionIndex];
      question[section] = value;
    },
    handleAddRemoveChoices: (
      state,
      action: PayloadAction<{
        actionType: "add" | "remove";
        actionIndex?: number;
        questionIndex: number;
      }>
    ) => {
      const { actionType, actionIndex, questionIndex } = action.payload;
      let question = state.questions[questionIndex];
      if (question) {
        const choices = question.choices;
        if (actionType === "add") {
          const newOpt = createNewOption();
          choices.push({
            ...newOpt,
            position: choices.length + 1,
          });
        } else if (actionType === "remove" && actionIndex !== undefined) {
          choices.splice(actionIndex, 1);
          choices.forEach((opt: any, i: number) => {
            opt.position = i + 1;
          });
        }
      }
    },
    handleChoiceModification: (
      state,
      action: PayloadAction<{
        id: string;
        section: "label" | "value";
        value: any;
        questionIndex: number;
      }>
    ) => {
      const { id, section, value, questionIndex } = action.payload;
      let question = state.questions[questionIndex];
      if (question) {
        question.choices.forEach((c: any) => {
          if (c.id === id) {
            let val = section === "value" ? parseFloat(value) : value;
            if (section === "value" && isNaN(val as number)) {
              val = "";
            }

            c[section] = val;
          }
        });
      }
    },
  },
});

export const {
  setSurveyDetails,
  setSurveyLoader,
  setSurveySubmitting,
  setSurveyError,
  handleAddRemoveChoices,
  handleChoiceModification,
  addSurveyQuestion,
  removeSurveyQuestion,
  insertSurveyQuestion,
  moveSurveyQuestion,
  changeQuestionType,
  handleQuestionModification,
  reset,
  resetSurveyState,
  setFetchError,
} = surveySlice.actions;

export default surveySlice.reducer;
