import { v4 as uuid } from "uuid";

export const createSurveyQuestionSkeleton = (type: string) => ({
  ...fieldMap(type),
  type,
  id: uuid(),
});

export const createNewOption = () => ({
  id: uuid(),
  label: "",
  value: "",
});

const basicField = {
  label: "",
  isRequired: false,
  description: "",
};

export const surveyChoiceField = (pos: number, label?: string) => {
  return {
    id: uuid(),
    label: label || "",
    position: pos,
    value: "",
  };
};

const multipleChoiceField = () => ({
  ...basicField,
  choices: [surveyChoiceField(1), surveyChoiceField(2)],
});

const yesNoField = () => ({
  ...basicField,
  choices: [surveyChoiceField(1, "Yes"), surveyChoiceField(2, "No")],
});

export const fieldMap = (type: string) => {
  switch (type) {
    case "radio":
      return multipleChoiceField();
    case "text":
      return basicField;
    case "yes_no":
      return yesNoField();
    case "rating_scale":
      return multipleChoiceField();
    default:
      return {};
  }
};

export const surveyTypes = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Multiple Choice",
    value: "radio",
  },
  {
    label: "Yes/No",
    value: "yes_no",
  },
  {
    label: "Rating Scale",
    value: "rating_scale",
  },
];
