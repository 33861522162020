import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { ModalBaseStyles, ModalHeader } from "../Common/styles/modal";
import http from "../../utils/http";
import { resetState } from "../../Redux/actions/resetAction";
import { AxiosResponse } from "axios";
import { toastMessage } from "../../utils/toast";
import { useAppDispatch } from "../../Redux/hooks";
import { ModalActionButtonStyles } from "./../Common/styles/modal";

type Props = {
  showModal: boolean;
  closeModal: () => void;
};

const ConfirmLogout = ({ showModal, closeModal }: Props) => {
  const dispatch = useAppDispatch();
  const [submitLoader, setSubmitLoader] = useState(false);

  const logout = async () => {
    try {
      setSubmitLoader(true);
      let res: AxiosResponse;
      res = await http.post("user/auth/logout");
      toastMessage("success", res?.data?.message);
    } catch (err) {
      console.log(err);
    } finally {
      setSubmitLoader(false);
      localStorage.clear();
      dispatch(resetState());
    }
  };

  return (
    <Modal open={showModal} onClose={closeModal}>
      <Box sx={{ ...ModalBaseStyles, minHeight: "20vh" }}>
        <ModalHeader title="Logout" onCloseClick={closeModal} />
        <Typography variant="subtitle1" fontWeight={600}>
          Are you sure you want to logout from your account?
        </Typography>
        <Box sx={{ ...ModalActionButtonStyles, mt: 2 }}>
          {!submitLoader ? (
            <>
              <Button onClick={closeModal} variant="outlined">
                Cancel
              </Button>
              <Button onClick={logout} variant="contained">
                Logout
              </Button>
            </>
          ) : (
            <CircularProgress size={25} />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmLogout;
