import { useRouteError } from "react-router-dom";
import React from "react";
import { Box, Typography, Button } from "@mui/material";

const ErrorRoute = () => {
  const error = useRouteError();
  console.log(error);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
        backgroundColor: "#FFF6EB",
      }}
    >
      <Typography
        sx={{
          fontSize: "28px",
        }}
      >
        Note : The App has been updated, Click Reload to Continue using the app.
      </Typography>
      <Typography
        sx={{
          fontSize: "20px",
          mb: 2,
          mt: 2,
        }}
      >
        Contact jp@mahalo.health if the error persists.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleReload}>
        Reload
      </Button>
    </Box>
  );
};

export default ErrorRoute;
