import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  NoDataContainer,
  StyledTableCell,
  TableBorderRadiusTopLeftRight,
} from "../../Common/styles/table";
import {
  CheckBox,
  CheckBoxOutlineBlank,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import { errorToastMessage, toastMessage } from "../../../utils/toast";
import { AxiosResponse } from "axios";
import http from "../../../utils/http";
import { ModalDrawerButtonStyles } from "../../Common/styles/modal";
import { formatFeaturesId } from "../../../utils/features";
import { DrawerForm } from "../../Common/styles/drawer";
// import { useNavigate } from "react-router-dom";

type Props = {
  refreshPage: () => void;
  handleBack: () => void;
  closeDrawer: () => void;
  handleNext: () => void;
  studyId: string;
};
const Features = ({
  refreshPage,
  closeDrawer,
  handleBack,
  studyId,
  handleNext,
}: Props) => {
  // const navigation = useNavigate();
  const [data, setData] = useState<any[]>([]);

  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let url = `/features`;
        const res: AxiosResponse = await http.get(url);
        const featuresData = res?.data?.data?.rows;
        const newData = featuresData?.map((feature: any) => ({
          id: feature?.id,
          name: feature?.name,
          expanded: false,
          selected: "no",
          subItems: feature?.childrenFeatures?.map((subFeature: any) => ({
            id: subFeature?.id,
            name: subFeature?.name,
            selected: false,
          })),
        }));
        setData(newData);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
      }
    };
    fetchData();
  }, [setLoading]);

  const handleExpandToggle = (id: string) => {
    setData((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, expanded: !item.expanded } : item
      )
    );
  };

  const handleCheckboxToggle = (parentId: string, subItemId?: string) => {
    setData((prevData) =>
      prevData.map((item) => {
        if (item.id === parentId) {
          if (subItemId) {
            const updatedSubItems = item.subItems.map((subItem: any) =>
              subItem.id === subItemId
                ? { ...subItem, selected: !subItem.selected }
                : subItem
            );

            const allSubItemsSelected = updatedSubItems.every(
              (subItem: any) => subItem.selected
            );
            const someSubItemsSelected = updatedSubItems.some(
              (subItem: any) => subItem.selected
            );

            return {
              ...item,
              subItems: updatedSubItems,
              selected: allSubItemsSelected
                ? "yes"
                : someSubItemsSelected
                ? "partial"
                : "no",
            };
          } else {
            const newSelectedState = item.selected === "yes" ? "no" : "yes";

            const updatedSubItems = item.subItems.map((subItem: any) => ({
              ...subItem,
              selected: newSelectedState === "yes",
            }));

            return {
              ...item,
              selected: newSelectedState,
              subItems: updatedSubItems,
            };
          }
        }
        return item;
      })
    );
  };

  const handleSubmit = async () => {
    try {
      setSubmitLoader(true);
      const selectedFeatures = formatFeaturesId(data);
      const body = {
        featureIds: selectedFeatures,
      };
      const res = await http.post(`/study/${studyId}/features`, body);
      toastMessage("success", res?.data?.message);
      setSubmitLoader(false);
      handleNext();
      // closeDrawer();
      // refreshPage();
      // navigation(`/studies/${studyId}/study-designer`);
    } catch (error) {
      errorToastMessage(error as Error);
    } finally {
      setSubmitLoader(false);
    }
  };

  return (
    <Box sx={DrawerForm}>
      <Typography fontSize="16px" fontWeight={400} color="text.secondary">
        Choose essential functionalities, such as participant, repeating data
        collection, survey packages, data queries, and export options, to
        customize your program.
      </Typography>
      <Divider sx={{ my: 2.5 }} />
      <Box sx={DrawerForm}>
        <Box>
          <Typography
            fontWeight={500}
            fontSize={20}
            color="text.primary"
            mb={2.5}
          >
            Features
          </Typography>
          <Table sx={TableBorderRadiusTopLeftRight}>
            <TableHead>
              <TableRow>
                <StyledTableCell
                  sx={{
                    width: "400px",
                  }}
                ></StyledTableCell>
                <StyledTableCell>Applicable</StyledTableCell>
              </TableRow>
            </TableHead>
            {!loading && data?.length > 0 && (
              <TableBody>
                {data?.map((row: any) => (
                  <Fragment key={row?.id}>
                    <TableRow>
                      <StyledTableCell>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <IconButton
                            onClick={() => handleExpandToggle(row?.id)}
                          >
                            {row?.expanded ? <ExpandLess /> : <ExpandMore />}
                          </IconButton>
                          <Typography
                            fontSize={16}
                            fontWeight={600}
                            color={"primary.main"}
                          >
                            {row?.name}
                          </Typography>
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Checkbox
                          checked={row?.selected === "yes"}
                          indeterminate={row?.selected === "partial"}
                          icon={<CheckBoxOutlineBlank />}
                          onChange={() => handleCheckboxToggle(row?.id)}
                          checkedIcon={<CheckBox />}
                        />
                      </StyledTableCell>
                    </TableRow>
                    {row?.expanded &&
                      row?.subItems?.map((subItem: any) => (
                        <TableRow key={subItem.id}>
                          <StyledTableCell>
                            <Typography
                              fontSize={16}
                              fontWeight={400}
                              sx={{ ml: 8 }}
                            >
                              {subItem.name}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Checkbox
                              checked={subItem.selected}
                              icon={<CheckBoxOutlineBlank />}
                              onChange={() =>
                                handleCheckboxToggle(row.id, subItem.id)
                              }
                              checkedIcon={<CheckBox />}
                            />
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </Fragment>
                ))}
              </TableBody>
            )}
          </Table>
          {!loading && data?.length === 0 && (
            <NoDataContainer>
              <Typography variant="body1" color="gray">
                No Data
              </Typography>
            </NoDataContainer>
          )}
          {loading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          )}
        </Box>
        <Box sx={{ mt: "auto" }}>
          <Divider />
          <Box sx={ModalDrawerButtonStyles}>
            {!submitLoader ? (
              <Button variant="contained" onClick={handleSubmit}>
                Next Step
              </Button>
            ) : (
              <CircularProgress size={25} />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Features;
